// 赋码设备出库Qrcode列表
// popQrcodeDetails
<template>

    <div class="popQrcodeDetails">
        <el-dialog title="设备清单"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="500px"
                   :before-close="close">

            <div class="bomList"
                 v-loading="loading">
                <div class="title">{{details.empType || "无"}}/{{details.provider || "无"}}/{{details.empTypeModel || "无"}}</div>
                <div class="list bomListWarp">
                    <div class="label">设备编号</div>
                    <template v-if="detailsInfo.length">
                        <div class="item"
                             v-for="qrCode in detailsInfo"
                             :key="qrCode">{{qrCode}}</div>
                    </template>
                    <div class="emptyBg"
                         v-else></div>
                </div>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popQrcodeDetails",

    props: {
        details: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            detailsInfo: {},
            loading: false, // 详情加载中
        };
    },

    created() {},

    mounted() {
        // 详情
        this.getDetails();
    },

    methods: {
        // 详情
        getDetails() {
            this.loading = true;
            let data = {
                flowId: this.$route.query.selected,
                modelId: this.details.modelId,
            };
            commonAPI
                .getFlowListQrCode(data)
                .then((res) => {
                    this.loading = false;
                    this.detailsInfo = res.content;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.bomList {
    background: #ffffff;
    border: 1px solid #ebeef5;
    .title {
        font-size: 16px;
        line-height: 36px;
        color: #333333;
        background: #e8f2fa;
        border-radius: 0px;
        padding: 0 20px;
    }
    .bomListWarp {
        padding: 20px 20px 0;
        overflow: hidden;
        .label {
            margin-bottom: 10px;
        }
        .item {
            padding: 10px 20px;
            line-height: 19px;
            border: 1px solid #dcdfe6;
            margin-bottom: 5px;
        }
        .item:nth-child(2n) {
            margin-right: 0;
        }
    }
}
</style>