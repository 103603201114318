// 出入库详情
<template>

    <div class='inoutDetails baseBg'>
        <backUp></backUp>
        <el-row>
            <el-col :span="6">
                <div class="infoBox"
                     v-if="typeof detailsInfo.flowMethod === 'object'">

                    <template>
                        <div class="cardBox"
                             v-if="detailsInfo.flowMethod.text.indexOf('组装') != -1">
                            <div class="cardTitle"><img src="../../assets/imgs/icon_zuzhuang.png"
                                     alt="">组装</div>
                            <p><span class="label">组装设备型号：</span><span class="value">{{detailsInfo.empType|| "无"}}/{{detailsInfo.provider|| "无"}}/{{detailsInfo.typeModel || detailsInfo.empTypeModel || "无"}}</span></p>
                            <p><span class="label">组装设备编号：</span><span class="value">{{detailsInfo.qrCode}}</span></p>
                            <p><span class="label">组装日期：</span><span class="value">{{detailsInfo.operTime}}</span></p>
                            <p><span class="label">操作人：</span><span class="value">{{detailsInfo.operUserName}}</span></p>
                        </div>
                        <div class="cardBox"
                             v-if="detailsInfo.flowMethod.text.indexOf('拆除') != -1">
                            <div class="cardTitle"><img src="../../assets/imgs/icon_chaichu.png"
                                     alt="">拆除</div>
                            <p><span class="label">拆除设备型号：</span><span class="value">{{detailsInfo.empType|| "无"}}/{{detailsInfo.provider|| "无"}}/{{detailsInfo.typeModel || detailsInfo.empTypeModel || "无"}}</span></p>
                            <p><span class="label">拆除设备编号：</span><span class="value">{{detailsInfo.qrCode}}</span></p>
                            <p><span class="label">拆除日期：</span><span class="value">{{detailsInfo.operTime}}</span></p>
                            <p><span class="label">操作人：</span><span class="value">{{detailsInfo.operUserName}}</span></p>
                        </div>
                        <!-- 正常状态 显示：出库、入库 -->
                        <div class="cardBox"
                             v-if="detailsInfo.flowMethod.text.indexOf('出库') != -1">
                            <div class="cardTitle"><img src="../../assets/imgs/icon_ruku.png"
                                     alt="">出库</div>
                            <p><span class="label">出库人：</span><span class="value">{{detailsInfo.operatorName}}</span></p>
                            <p><span class="label">出库时间：</span><span class="value">{{detailsInfo.createTime}}</span></p>
                            <p><span class="label">出库设备数量：</span><span class="value">{{detailsInfo.inCount}}</span></p>
                        </div>
                        <div class="cardBox"
                             v-else>
                            <div class="cardTitle"><img src="../../assets/imgs/icon_ruku.png"
                                     alt="">入库</div>
                            <p><span class="label">入库人：</span><span class="value">{{detailsInfo.operatorName}}</span></p>
                            <p><span class="label">入库时间：</span><span class="value">{{detailsInfo.createTime}}</span></p>
                            <p><span class="label">入库设备数量：</span><span class="value">{{detailsInfo.inCount}}</span></p>
                        </div>
                    </template>
                    <template v-if="detailsInfo.relateFlowId != null">
                        <!-- 异常状态 -->
                        <template v-if="detailsInfo.flowMethod.text === '采购入库'">
                            <div class="cardBox"
                                 v-if="detailsInfo.purchaseOrderListVo">
                                <div class="cardTitle"><img src="../../assets/imgs/icon_bushu.png"
                                         alt="">已删除采购</div>
                                <p><span class="label">异常出库记录：</span><span class="value">{{detailsInfo.relateFlowId}}
                                        <el-link type="primary"
                                                 @click="toOtherDetails(detailsInfo.relateFlowId)">查看</el-link>
                                    </span>
                                </p>
                            </div>
                        </template>
                        <template v-if="detailsInfo.flowMethod.text === '部署出库'">
                            <div class="cardBox"
                                 v-if="detailsInfo.taskVo">
                                <div class="cardTitle"><img src="../../assets/imgs/icon_bushu.png"
                                         alt="">已删除部署</div>
                                <p><span class="label">异常入库记录：</span><span class="value">{{detailsInfo.relateFlowId}}
                                        <el-link type="primary"
                                                 @click="toOtherDetails(detailsInfo.relateFlowId)">查看</el-link>
                                    </span>
                                </p>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="detailsInfo.flowMethod.text.indexOf('异常') != -1">
                            <div class="cardBox">
                                <div class="cardTitle"><img src="../../assets/imgs/icon_bushu.png"
                                         alt="">异常</div>
                                <p v-if="detailsInfo.taskVo">关联 “{{detailsInfo.projectName}}” 项目的 “{{detailsInfo.taskVo.name}}” 计划的部署删除</p>
                                <p v-if="detailsInfo.purchaseOrderListVo">编号{{detailsInfo.purchaseOrderListVo.code}}采购清单删除</p>
                                <p><span class="label">描述：</span><span class="value">{{detailsInfo.description}}</span></p>
                            </div>
                        </template>
                        <template v-if="detailsInfo.flowMethod.text === '采购入库'">
                            <div class="cardBox"
                                 v-if="detailsInfo.purchaseOrderListVo">
                                <div class="cardTitle"><img src="../../assets/imgs/icon_caigou.png"
                                         alt="">采购</div>
                                <p><span class="label">采购编号：</span><span class="value">{{detailsInfo.purchaseOrderListVo.code}}</span></p>
                                <p><span class="label">采购日期：</span><span class="value">{{detailsInfo.purchaseOrderListVo.purchaseDate}}</span></p>
                                <p><span class="label">操作人：</span><span class="value">{{detailsInfo.purchaseOrderListVo.purchasePersonName}}</span></p>
                                <p><span class="label">备注：</span><span class="value">{{detailsInfo.purchaseOrderListVo.remark}}</span></p>
                            </div>
                        </template>
                        <template v-if="detailsInfo.flowMethod.text === '采购入库' || detailsInfo.flowMethod.text === '部署出库'">
                            <div class="cardBox"
                                 v-if="detailsInfo.taskVo">
                                <div class="cardTitle"><img src="../../assets/imgs/icon_bushu.png"
                                         alt="">部署</div>
                                <p><span class="label">项目名称：</span><span class="value">{{detailsInfo.projectName}}</span></p>
                                <p><span class="label">实施计划名称：</span><span class="value">{{detailsInfo.taskVo.name}}</span></p>
                                <p><span class="label">目标客户：</span><span class="value">{{detailsInfo.taskVo.customerCompanyName}}</span></p>
                                <p><span class="label">部署开始日期：</span><span class="value">{{detailsInfo.taskVo.startTime}}</span></p>
                                <p v-if="detailsInfo.taskVo.finishTime"><span class="label">部署完成日期：</span><span class="value">{{detailsInfo.taskVo.finishTime}}</span></p>
                                <p><span class="label">实施负责人：</span><span class="value">{{detailsInfo.taskVo.operatorName}}</span></p>
                                <p><span class="label">任务详情：</span><span class="value">{{detailsInfo.taskVo.description}}</span></p>
                            </div>
                        </template>
                    </template>

                </div>
            </el-col>
            <el-col :span="18">
                <div class="listBox">
                    <div class="tableBox">
                        <el-table :data="detailsInfo.reportDeivces"
                                  border
                                  stripe>
                            <el-table-column v-for="col in tableData.columns"
                                             :prop="col.id"
                                             :key="col.id"
                                             :label="col.label"
                                             :min-width="col.width">
                            </el-table-column>
                            <el-table-column prop='id'
                                             label='操作'
                                             min-width='100'>
                                <template slot-scope='scope'>
                                    <el-link type='primary'
                                             v-if="scope.row.qrCode"
                                             @click='openpopQrcodeDetails(scope.row)'>查看</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-col>
        </el-row>

        <!-- 赋码设备出库Qrcode列表 -->
        <PopQrcodeDetails v-if="popQrcodeDetailsVisible"
                          :details="editDetails"
                          @close="closepopQrcodeDetails"></PopQrcodeDetails>

    </div>

</template>

<script>
import PopQrcodeDetails from "./components/popQrcodeDetails";
import { Loading } from "element-ui";
import API from "@/api/stockManage.js";
import backUp from "@/components/backUp";

export default {
    name: "inoutDetails",

    props: [],

    components: {
        PopQrcodeDetails,
        backUp,
    },

    data() {
        return {
            tableData: {
                columns: [
                    {
                        id: "empType",
                        label: "设备类型",
                        width: "180",
                    },
                    {
                        id: "empTypeModel",
                        label: "规格型号",
                        width: "180",
                    },
                    {
                        id: "provider",
                        label: "供应商",
                        width: "180",
                    },
                    {
                        id: "empCount",
                        label: "数量",
                        width: "180",
                    },
                ],
                data: [],
            },
            detailsInfo: "", // 出入库详情
            popQrcodeDetailsVisible: false, // 赋码设备出库Qrcode列表弹窗显示
            editDetails: null, // 行信息
        };
    },

    created() {},

    mounted() {
        // 出入库详情
        this.inoutRecordDetails();
    },

    methods: {
        // 查看异常的出入库详情
        toOtherDetails(id) {
            this.$router.push({
                path: "/stockManage/inoutDetails",
                query: {
                    selected: id,
                },
            });
        },
        // 查看详情
        details(item) {
            this.$router.push({
                path: "/stockManage/deviceInoutRecord",
                query: { modelId: item.modelId, empId: item.equipmentId },
            });
        },
        // 赋码设备出库Qrcode列表 打开弹框
        openpopQrcodeDetails(row = null) {
            this.editDetails = row;
            this.popQrcodeDetailsVisible = true;
        },
        // 赋码设备出库Qrcode列表 关闭弹框
        closepopQrcodeDetails() {
            this.editDetails = null;
            this.popQrcodeDetailsVisible = false;
        },
        // 出入库详情
        inoutRecordDetails() {
            let loading = Loading.service({
                target: document.querySelector(".cardBox"),
            });
            API.inoutRecordDetails({
                flowId: this.$route.query.selected,
            })
                .then((res) => {
                    loading.close();
                    this.detailsInfo = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
