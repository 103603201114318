// 组件 - 返回上一级
<template>

    <div class="backUp">
        <span @click="backUp">
            <i class="el-icon-arrow-left" /> 返回上级页面
        </span>
    </div>

</template>

<script>
export default {
    name: "backUp",

    props: {
        to: {
            // 指定跳转地址
            type: String,
            default: "",
        },
        isNeedTip: {
            // 是否需要弹窗提示是否保存
            type: Boolean,
            default: false,
        },
    },

    components: {},

    data() {
        return {};
    },

    created() {},

    mounted() {},

    methods: {
        backUp() {
            if (this.isNeedTip) {
                this.$confirm(
                    "请确认保存内容，返回后没有保存的数据将无法恢复",
                    "提示",
                    {
                        confirmButtonText: "我已保存，要返回上一级",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(() => {
                    this.toBack();
                });
            } else {
                this.toBack();
            }
        },
        toBack() {
            if (this.to && this.to !== null && this.to !== "null") {
                this.$router.push({
                    path: this.to,
                });
            } else {
                this.$router.back(-1);
            }
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
// 返回上一级
.backUp {
    margin-bottom: 15px;
    color: #0092ff;
    font-size: 14px;
}
.backUp span {
    cursor: pointer;
}
</style>
